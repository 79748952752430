
export enum IGET_TOKEN_REQUEST {
    REQUEST = "getToken/GET_TOKEN_REQUEST",
    SUCCESS = "getToken/GET_TOKEN_SUCCESS",
    FAILED = "getToken/GET_TOKEN_FAILED"
};

export interface IGetTokenRequest {
    request: {
        username: string;
    }
};

export interface IToken {
    token: string;
    explorationAccess: boolean;
    sieveAccess: boolean;
    carrierAccess: boolean;
    dryPlantAccess: boolean;
    washPlantAccess: boolean;
    yhdCarrierAccess: boolean;
    warehouseAccess: boolean;
    complaintLoggingAccess: boolean;
    silPitSummaryAccess: boolean;
    yhdPitSummaryAccess: boolean;
    simosAccess: ISIMOSAccess;
};

export interface ISIMOSAccess {
    access: string;
    customerHoldAccess: boolean;
    superAdmin : boolean;
}

export interface IPassTokenWithRequest {
    token: string;
};