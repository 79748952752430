import React, { Component } from "react";
import { connect } from "react-redux";
import { IStore } from "../redux/reducers";
import { STATUS_ENUM, hasPayload, Server } from "../redux/server";
import { RouteComponentProps } from "react-router";
import { IToken } from "../redux/sil/dryPlant/token/getTokenConstants";
import { SilAPIResponse } from "./shared/publicInterfaces";
import { getTokenStatus } from "../redux/sil/dryPlant/token/getTokenAccessor";
import { SECURED_FILE_ROUTES } from "./routes";

interface IProtectedIFrameServerProps {
    token: Server<SilAPIResponse<IToken>>;
}

interface IProtectedIFrameOwnProps { }

type IProtectedIFrameProps = RouteComponentProps &
    IProtectedIFrameOwnProps &
    IProtectedIFrameServerProps;

class ProtectedIFrame extends Component<IProtectedIFrameProps> {
    componentDidMount() {

    }

    render() {
        if(this.props.token.kind === STATUS_ENUM.SUCCEEDED){
            return <iframe
                style={{
                    width: "100vw",
                    height: "100vh",
                    border: "none",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
                src={window.location.href}
            />
        } else {
            return <h1>
                You are not authorized to access this page
            </h1>
        }

    }
}

const mapStateToProps = (state: IStore): IProtectedIFrameServerProps => ({
    token: getTokenStatus(state),
});

export default connect(mapStateToProps)(ProtectedIFrame);