import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { LAPaperWithPadding } from "./shared/paper";
import { ROUTE } from "./routes";
import LAGrid from "./shared/grid";
import LAGridItem from "./shared/gridList";
import { DARK_RED_COLOR, MEDIA_QUERY_PHONE } from "./shared/theme";
import { getTokenStatus } from "../redux/sil/dryPlant/token/getTokenAccessor";
import { IGetTokenRequest, IToken } from "../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../redux/sil/dryPlant/token/getTokenActions";
import { hasPayload, isNotLoaded, Server } from "../redux/server";
import { SilAPIResponse } from "./shared/publicInterfaces";
import { IDispatch, IStore } from "../redux/reducers";
import { connect } from "react-redux";
import { userName } from "./shared/constExports";
import PageSpacing from "./shared/pageSpacing";
import SilRoleCheck from "./shared/silRoleCheck";


interface ISilHomeStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
};

interface ISilHomeDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
};

const SilHomeStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    .title {
        color: ${DARK_RED_COLOR};
        font-size: 40px;
        font-weight: bold;
        text-shadow: 2px 1px #b30003;
    };
    
    .homeMenu {
        color: #000;
        height: 150px;
        cursor: pointer;
        border-radius: 25px;
        border: 4px solid #fff;
        background-color: #E7F2F8;
        box-shadow: 0 2px 10px 0 #b30003;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 5.0;
        -webkit-transition: background 2.5s linear;
        -moz-transition: background 2.5s linear;
        -o-transition: background 2.5s linear;
        -ms-transition: background 2.5s linear;
        transition: background 2.5s linear;
    };

    .appTitle {
        font-size: 30px;
        font-weight: bold;
        margin-top: 15%;
        text-shadow: 2px 1px #b30003;
    };

    .carrierTitle {
        color: #8289dd;
    };

    .dryPlantTitle {
        color: #a9e7ff;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type ISilHomeProps = RouteComponentProps &
    ISilHomeDispatchProps & ISilHomeStoreProps;

class YHDHome extends PureComponent<ISilHomeProps> {

    public constructor(props: ISilHomeProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.checkTokenStatus();
    };

    public componentDidUpdate(prevProps: ISilHomeProps): void {
        if (this.props !== prevProps)
            this.checkTokenStatus();
    };


    public render(): ReactNode {
        const { getToken } = this.props;
        const getUsertoken = hasPayload(getToken) && getToken.payload.response;
        // console.log(getUsertoken)

        return (
            <PageSpacing title="SIL" description="SIL" fixedSpaceOnSmallerScreens={true}>
                <SilHomeStyles>
                    <LAGrid spacing={3} className="text-center">

                        <LAGridItem xs={12}>
                            <h2 className="title">YHD HOME</h2>
                        </LAGridItem>

                        <SilRoleCheck error={false} roleFor="yhdCarrierAccess">
                            <LAGridItem xs={2}>
                                <div className="homeMenu" onClick={this.onCarrier}>
                                    <h2 className="appTitle carrierTitle">Carriers</h2>
                                </div>
                            </LAGridItem>
                        </SilRoleCheck>
                        <LAGridItem xs={2}>
                                <div className="homeMenu" onClick={this.onDispatch}>
                                    <h2 className="appTitle carrierTitle">Dispatch/Loads</h2>
                                </div>
                            </LAGridItem>
                        {getUsertoken && getUsertoken.yhdPitSummaryAccess && <LAGridItem xs={2}>
                            <div className="homeMenu" onClick={this.onPitSummary}>
                                <h2 className="appTitle carrierTitle">Pit Summary</h2>
                            </div>
                        </LAGridItem>}
                    </LAGrid>
                </SilHomeStyles>
            </PageSpacing>
        );
    }

    private onCarrier = (): void => {
        this.props.history.push(ROUTE.PROPSENSE.YHD.CARRIERS.INDEX);
    };

    private onDispatch = (): void => {
        window.open(`https://yhd.propsense.com/`, "_blank");
    };

    private onPitSummary = (): void => {
        window.open(`https://yhd.propsense.com/YHD_pit/yhd_pit_summary.html`, "_blank");
    };

    private checkTokenStatus = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            })
    };

}

const mapStateToProps = (state: IStore): ISilHomeStoreProps => ({
    getToken: getTokenStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISilHomeDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(YHDHome);